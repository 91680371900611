import { Injectable } from "@angular/core";
import {
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import {
  catchError,
  filter,
  switchMap,
  take,
  windowTime,
} from "rxjs/operators";
import { AuthService } from "./auth.service";
import { Router } from "@angular/router";

const TOKEN_HEADER_KEY = "x-access-token";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(private authService: AuthService, private router: Router) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.authService.getToken();
    let authReq = req;

    if (token) {
      authReq = this.addTokenHeader(req, token);
    }

    return next.handle(authReq).pipe(
      catchError((error: any) => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          if (authReq.url.includes("/refresh")) {
            this.authService.signOut();
            this.router.navigate(["auth/login"]);
          } else if (!authReq.url.includes("auth/login")) {
            return this.handle401Error(authReq, next);
          }
        }
        return throwError(error);
      })
    );
  }

  private handle401Error(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      const refreshToken = this.authService.getRefreshToken();

      if (refreshToken) {
        return this.authService.refreshToken(refreshToken).pipe(
          switchMap((response: any) => {
            this.isRefreshing = false;
            this.authService.saveToken(response.body.data.accessToken);
            this.refreshTokenSubject.next(response.body.data.accessToken);
            sessionStorage.setItem(
              "access_token",
              JSON.stringify(response.body.data.accessToken)
            );
            let refreshToken = response.body.data.refreshToken;
            sessionStorage.setItem(
              "refresh_token",
              refreshToken
            );

            const authReqWithNewToken = this.addTokenHeader(
              request,
              response.body.data.accessToken
            );
            return next.handle(authReqWithNewToken);
          }),
          catchError((error: any) => {
            this.isRefreshing = false;
            //this.authService.signOut();
           // this.router.navigate(["auth/login"]);
            return throwError(error);
          })
        );
      } else {
        this.isRefreshing = false;
        this.authService.signOut();
        this.router.navigate(["auth/login"]);
      }
    } else {
      return this.refreshTokenSubject.pipe(
        filter((token) => token !== null),
        take(1),
        switchMap((token) => {
          const authReqWithNewToken = this.addTokenHeader(
            request,
            sessionStorage.getItem("access_token")
          );
          return next.handle(authReqWithNewToken);
        })
      );
    }
  }

  private addTokenHeader(
    request: HttpRequest<any>,
    token: string
  ): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        [TOKEN_HEADER_KEY]: token,
      },
    });
  }
}

export const authInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
];
