import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
const TOKEN_KEY = "auth-token";
const REFRESHTOKEN_KEY = "refresh_token";
const USER_KEY = "auth-user";
@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(private http: HttpClient) {}

  login(postData) {
    return this.http.post(
      environment.baseUrl + environment.path + "auth",
      postData,
      { observe: "response" }
    );
  }

  logout() {
    return this.http.get(environment.baseUrl + environment.path + "logout");
  }

  /**
   * Function -- forgotPassword()
   * Use -- API for forgot password.
   * @param postData
   */
  forgotPassword(postData) {
    return this.http.get(
      environment.baseUrl +
        environment.path +
        "forgot-password?emailId=" +
        postData.email,
      { observe: "response" }
    );
  }

  refreshToken(token: string) {
    return this.http.get(
      environment.baseUrl +
        environment.path +
        "refreshtoken?refreshToken=" +
        token,
      { observe: "response" }
    );
  }

  signOut(): void {
    window.sessionStorage.clear();
  }

  public saveToken(token: string): void {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY, token);
    const user = this.getUser();
    if (user.id) {
      this.saveUser({ ...user, accessToken: token });
    }
  }

  public getToken(): string | null {
    return window.sessionStorage.getItem(TOKEN_KEY);
  }
  public saveRefreshToken(token: string): void {
    window.sessionStorage.removeItem(REFRESHTOKEN_KEY);
    window.sessionStorage.setItem(REFRESHTOKEN_KEY, token);
  }
  public getRefreshToken(): string | null {
    return window.sessionStorage.getItem(REFRESHTOKEN_KEY);
  }
  public saveUser(user: any): void {
    window.sessionStorage.removeItem(USER_KEY);
    window.sessionStorage.setItem(USER_KEY, JSON.stringify(user));
  }
  public getUser(): any {
    const user = window.sessionStorage.getItem(USER_KEY);
    if (user) {
      return JSON.parse(user);
    }
    return {};
  }

  getMenu(userId) {
    return this.http.get(
      environment.baseUrl + environment.path + "menu/" + userId,
      { observe: "response" }
    );
  }
}
