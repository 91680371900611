import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
  `,
})
export class FooterComponent implements OnInit{
  
  year: any;
  
  ngOnInit() {
    /** Prints current year */
    this.year = new Date().getFullYear();
  }
}
